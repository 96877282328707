import React, { useEffect, useState } from "react";
import LoadingComponent from "../../components/LoadingComponent";
import ContactUsForm from "../../components/ContactUsForm";
import savingSchemeVideo from "../../assets/video/saving-scheme.mp4";

import styles from "../../styles/SavingScheme.module.scss";
import {
  saving_scheme_details,
  saving_scheme_table,
} from "../../util/SavingScheme";

function SavingScheme() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const delay = 1250;
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, delay);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className={styles.savingSchemeContainer}>
        <div className={styles.savingLeftSide}>
          <div className={styles.savingSchemeDetail}>
            <h4 className={styles.savingSchemeDetailTerms}>
              Terms & Conditions:
            </h4>
            <ol>
              {saving_scheme_details.map((detail, index) => (
                <li key={index} className={styles.schemeDetail}>
                  {detail}
                </li>
              ))}
            </ol>
          </div>
          <div className={styles.savingSchemeTable}>
            <table>
              <tbody>
                {saving_scheme_table.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, colIndex) =>
                      rowIndex === 0 ? (
                        <th key={colIndex}>{cell}</th>
                      ) : (
                        <td key={colIndex}>{cell}</td>
                      )
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <p className={styles.SSFromHeading}>Saving Scheme Form</p>
          <div className={styles.contactUsFormSS}>
            <ContactUsForm text="Saving Scheme details" />
          </div>
        </div>
        <div className={styles.savingRightSide}>
          <video
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            src={savingSchemeVideo}
            autoPlay={true}
            loop
            muted={false}
            // controls
          />
        </div>
      </div>
      {isLoading && <LoadingComponent isLoading={isLoading} time={120} />}
    </>
  );
}

export default SavingScheme;
