import React, { useEffect, useState } from "react";
import styles from "../styles/Header.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { List, X } from "@phosphor-icons/react";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isSticky, setSticky] = useState(false);
  const [selectedPage, setSelectedPage] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [showNavInMobile, setShowNavInMobile] = useState(false);

  const navItems = [
    { label: "HOME", page: "" },
    { label: "PRODUCTS", page: "products" },
    { label: "ABOUT US", page: "about" },
    { label: "CONTACT US", page: "contact" },
    { label: "SAVING SCHEME", page: "saving-scheme" },
    { label: "BOOK APPOINTMENT", page: "book-appointment" },
  ];

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const currentPathname = location.pathname;
    const selectedItem = navItems.find(
      (item) => item.page === currentPathname.slice(1)
    );
    if (selectedItem) {
      setSelectedPage(selectedItem.page);
    } else {
      setSelectedPage("");
    }
  }, [location.pathname, navItems]);

  const handleLogoClick = () => {
    navigate("/");
  };

  const handleNavItemClick = (page) => {
    setSelectedPage(page);
    navigate(`/${page}`);
    setShowNavInMobile(false);
  };

  const toggleNavBarInMobile = () => {
    setShowNavInMobile((prev) => !prev);
  };

  return (
    <>
      <header className={`${styles.header} ${isSticky ? styles.sticky : ""}`}>
        <div className={styles.logoContainer} onClick={handleLogoClick}>
          <img
            src={require("../assets/images/Logo.png")}
            alt="AABUSHAN"
            className={styles.logoImage}
          />
        </div>
        {width > 480 ? (
          <div className={styles.navContainer}>
            {navItems.map((item) => (
              <p
                key={item.page}
                className={`${styles.navComponent} ${
                  selectedPage === item.page ? styles.selected : ""
                }`}
                onClick={() => handleNavItemClick(item.page)}
              >
                {item.label}
              </p>
            ))}
          </div>
        ) : (
          <div
            className={styles.menuIconContainer}
            onClick={toggleNavBarInMobile}
          >
            <List size={24} color="#691b1d" />
          </div>
        )}
        {showNavInMobile && width < 480 ? (
          <div className={styles.navContainerInMobile}>
            <div
              className={styles.menuCrossIconContainer}
              onClick={toggleNavBarInMobile}
            >
              <X size={24} color="#691b1d" />
            </div>
            {navItems.map((item) => (
              <p
                key={item.page}
                className={`${styles.navComponentMobile} ${
                  selectedPage === item.page ? styles.selected : ""
                }`}
                onClick={() => handleNavItemClick(item.page)}
              >
                {item.label}
              </p>
            ))}
          </div>
        ) : null}
      </header>
    </>
  );
};

export default Header;
