import React from "react";
import { bestSeller } from "../../../util/HomeUtil";

import styles from "../../../styles/BestSeller.module.scss";

function BestSeller() {
  return (
    <div className={styles.bestSellerContainer}>
      {bestSeller.map((item) => {
        const imageUrl = require(`../../../assets/images/${item.imageUrl}`);
        return (
          <div key={item.id} className={styles.imageWrapper}>
            <img
              className={styles.imageClass}
              src={imageUrl}
              alt={`Best Seller ${item.id}`}
            />
            <span className={styles.imageName}>{item.name}</span>
          </div>
        );
      })}
    </div>
  );
}

export default BestSeller;
