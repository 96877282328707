import React from "react";

function PrivacyPolicy() {
  return (
    <div style={{ margin: "1rem" }}>
      <h3>Privacy Policy</h3>
      <p>
        This privacy policy sets out how Aabushan uses and protects any
        information that you give to Aabushan when you use this website/mobile
        apps.
      </p>
      <p>
        Aabushan is committed to ensuring that your privacy is protected. Should
        we ask you to provide certain information by which you can be identified
        when using this website/mobile apps, then you can be assured that it
        will only be used in accordance with this privacy statement.
      </p>
      <p>
        Aabushan may change this policy from time to time by updating this page.
        You should check this page from time to time to ensure that you are
        happy with any changes. This policy is effective from 10 April 2024.
      </p>
      <p>
        What we collect We may collect the following information:
        <ul>
          <li>Name and job title</li>
          <li>Contact information including email address</li>
          <li>
            Demographic information such as postcode, preferences and interests
          </li>
          <li>Other information relevant to customer surveys and/or offers</li>
        </ul>
      </p>
      <p>
        What we do with the information we gather We require this information to
        understand your needs and provide you with a better service, and in
        particular for the following reasons:
        <ul>
          <li> Internal record keeping</li>
          <li>
            We may use the information to improve our products and services
          </li>
          <li>
            We may periodically send promotional emails about new products,
            special offers or other information which we think you may find
            interesting using the email address which you have provided
          </li>
          <li>
            From time to time, we may also use your information to contact you
            for market research purposes. We may contact you by email, phone,
            fax or mail. We may use the information to customize the website
            according to your interests
          </li>
        </ul>
      </p>
      <h4>Security</h4>
      <p>
        We are committed to ensuring that your information is secure. In order
        to prevent unauthorized access or disclosure we have put in place
        suitable physical, electronic and managerial procedures to safeguard and
        secure the information we collect online.
      </p>
      <h4>How We Use Cookies</h4>
      <p>
        A cookie is a small file which asks permission to be placed on your
        computer’s hard drive. Once you agree, the file is added and the cookie
        helps analyze web traffic or lets you know when you visit a particular
        site. Cookies allow web applications to respond to you as an individual.
        The web application can tailor its operations to your needs, likes and
        dislikes by gathering and remembering information about your
        preferences.
      </p>
      <p>
        We use traffic log cookies to identify which pages are being used. This
        helps us analyze data about webpage traffic and improve our website in
        order to tailor it to customer needs. We only use this information for
        statistical analysis purposes and then the data is removed from the
        system.
      </p>

      <p>
        Overall, cookies help us provide you with a better website, by enabling
        us to monitor which pages you find useful and which you do not. A cookie
        in no way gives us access to your computer or any information about you,
        other than the data you choose to share with us.
      </p>

      <p>
        You can choose to accept or decline cookies. Most web browsers
        automatically accept cookies, but you can usually modify your browser
        setting to decline cookies if you prefer. This may prevent you from
        taking full advantage of the website/mobile apps.
      </p>
      <h4>Links to other website/mobile apps:</h4>
      <p>
        Our website/mobile apps may contain links to other website/mobile apps
        of interest. However, once you have used these links to leave our site,
        you should note that we do not have any control over that other
        website/mobile apps. Therefore, we cannot be responsible for the
        protection and privacy of any information which you provide whilst
        visiting such sites and such sites are not governed by this privacy
        statement. You should exercise caution and look at the privacy statement
        applicable to the website/mobile apps in question.
      </p>
      <h4>Controlling your personal information</h4>
      <p>
        You may choose to restrict the collection or use of your personal
        information in the following ways:
        <ul>
          <li>
            Whenever you are asked to fill in a form on the website/mobile apps,
            look for the box that you can click to indicate that you do not want
            the information to be used by anybody for direct marketing purposes
          </li>
          <li>
            If you have previously agreed to us using your personal information
            for direct marketing purposes, you may change your mind at any time
            by writing to or emailing us at info@aabushan.in
          </li>
        </ul>
      </p>

      <p>
        {" "}
        We will not sell, distribute or lease your personal information to third
        parties unless we have your permission or are required by law to do so.
        We may use your personal information to send you promotional information
        about third parties which we think you may find interesting if you tell
        us that you wish this to happen.
      </p>

      <p>
        You may request details of personal information which we hold about you
        under the Data Protection Act 1998. A small fee will be payable. If you
        would like a copy of the information held on you please write to
        Aabushan, Jayanagar, Bangalore, India
      </p>
      <p>
        If you believe that any information we are holding on you is incorrect
        or incomplete, please write to or email us as soon as possible, at the
        below given address. We will promptly correct any information found to
        be incorrect.
      </p>
      <p>
        Users who wish to remove their account from Aabushan Platform, may send
        a request to info@aabushan.in from their registered email id. Upon
        success verification, the account might be removed from the Aabushan
        Platform
      </p>
    </div>
  );
}

export default PrivacyPolicy;
