import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import styles from "../../../styles/Carousel.module.scss";
import { useNavigate } from "react-router-dom";
import { Star } from "@phosphor-icons/react";

function CarouselComponent({ slideData, width }) {
  const navigate = useNavigate();

  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleCorouselButtonClick = (link) => {
    navigate(link);
  };

  return (
    <div className={styles.carouselContainer}>
      <Carousel activeIndex={index} onSelect={handleSelect}>
        {slideData.map((slide) => {
          let imageUrl = require(`../../../assets/images/${slide?.imageUrl}`);
          return (
            <Carousel.Item key={slide.id}>
              <img
                className={styles.imageClass}
                src={imageUrl}
                alt={`Slide ${slide.id}`}
              />
              {!!slide?.buttonText && (
                <Carousel.Caption>
                  <div className={styles.carouselCaption}>
                    <button
                      className={styles.carouselButtons}
                      onClick={() => handleCorouselButtonClick(slide?.navigate)}
                    >
                      {slide.buttonText}
                    </button>
                    <p className={styles.carouselDescription}>
                      <Star
                        size={width <= 768 ? 12 : 22}
                        color="#9e9e00"
                        weight="fill"
                      />{" "}
                      Rated 4.0 star based on 1000+ reviews
                    </p>
                  </div>
                </Carousel.Caption>
              )}
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
}

export default CarouselComponent;
