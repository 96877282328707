// const API_URL = "https://13.234.254.97:8080";
const API_URL = "https://aabushan.in:8080";

export const API_GET_JEWELLERY_TYPE = `${API_URL}/api/aabushan/public/jewellery-types`;
export const API_GET_JEWELLERY_MATERIAL = `${API_URL}/api/aabushan/public/jewellery-materials`;
export const API_GET_TAGS = `${API_URL}/api/aabushan/public/tags`;
export const API_GET_PRODUCTS = `${API_URL}/api/aabushan/public/products`;
export const API_POST_FORM = `${API_URL}/api/aabushan/public/contact-forms`;
export const API_GET_APPOINTMENT_SLOTS = `${API_URL}/api/aabushan/public/appointments`;
export const API_MAKE_BOOKING = `${API_URL}/api/aabushan/public/booking`;
