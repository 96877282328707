import {
  SET_JEWELLERY_TYPE_DATA,
  SET_JEWELLERY_TYPE_ERROR,
  FETCHING_JEWELLERY_TYPE_DATA,
  SET_JEWELLERY_MATERIAL_DATA,
  SET_JEWELLERY_MATERIAL_ERROR,
  FETCHING_JEWELLERY_MATERIAL_DATA,
  SET_TAGS_DATA,
  SET_TAG_ERROR,
  FETCHING_TAGS_DATA,
  SET_PRODUCT_DATA,
  SET_PRODUCT_ERROR,
  FETCHING_PRODUCT_DATA,
  RESET_DATA,
} from "../actions/types";

const initialState = {
  jewelleryTypeData: [
    { jewelleryTypeId: "1", jewelleryType: "Necklace" },
    { jewelleryTypeId: "2", jewelleryType: "Anklet" },
    { jewelleryTypeId: "3", jewelleryType: "Pendant" },
    { jewelleryTypeId: "4", jewelleryType: "Bracelet" },
    { jewelleryTypeId: "5", jewelleryType: "Earring" },
    { jewelleryTypeId: "6", jewelleryType: "Ring" },
    { jewelleryTypeId: "7", jewelleryType: "Brooch" },
    { jewelleryTypeId: "8", jewelleryType: "Bangle" },
  ],
  jewelleryTypeError: null,
  fetchingJewelleryType: false,
  jewelleryMaterialData: [
    { jewelleryMaterialId: "1", jewelleryMaterial: "Gold" },
    { jewelleryMaterialId: "2", jewelleryMaterial: "Silver" },
    { jewelleryMaterialId: "3", jewelleryMaterial: "Diamond" },
    { jewelleryMaterialId: "4", jewelleryMaterial: "Platinum" },
  ],
  jewelleryMaterialError: null,
  fetchingJewelleryMaterial: false,
  tagsData: [],
  tagError: null,
  fetchingTags: false,
  productData: [],
  productError: null,
  fetchingProduct: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_JEWELLERY_TYPE_DATA:
      return {
        ...state,
        jewelleryTypeData: action.payload,
        fetchingJewelleryType: false,
      };
    case SET_JEWELLERY_TYPE_ERROR:
      return {
        ...state,
        jewelleryTypeError: action.payload,
        fetchingJewelleryType: false,
      };
    case FETCHING_JEWELLERY_TYPE_DATA:
      return {
        ...state,
        fetchingJewelleryType: action.payload,
      };
    case SET_JEWELLERY_MATERIAL_DATA:
      return {
        ...state,
        jewelleryMaterialData: action.payload,
        fetchingJewelleryMaterial: false,
      };
    case SET_JEWELLERY_MATERIAL_ERROR:
      return {
        ...state,
        jewelleryMaterialError: action.payload,
        fetchingJewelleryMaterial: false,
      };
    case FETCHING_JEWELLERY_MATERIAL_DATA:
      return {
        ...state,
        fetchingJewelleryMaterial: action.payload,
      };
    case SET_TAGS_DATA:
      return {
        ...state,
        tagsData: action.payload,
        fetchingTags: false,
      };
    case SET_TAG_ERROR:
      return {
        ...state,
        tagError: action.payload,
        fetchingTags: false,
      };
    case FETCHING_TAGS_DATA:
      return {
        ...state,
        fetchingTags: action.payload,
      };
    case SET_PRODUCT_DATA:
      return {
        ...state,
        productData: action.payload,
        fetchingProduct: false,
      };
    case SET_PRODUCT_ERROR:
      return {
        ...state,
        productError: action.payload,
        fetchingProduct: false,
      };
    case FETCHING_PRODUCT_DATA:
      return {
        ...state,
        fetchingProduct: action.payload,
      };
    case RESET_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
