import React, { useEffect, useState } from "react";
import ContactUsForm from "../../components/ContactUsForm";
import LoadingComponent from "../../components/LoadingComponent";

import styles from "../../styles/ContactUs.module.scss";
import { EnvelopeSimple, MapPin, Phone } from "@phosphor-icons/react";
import { GST } from "../../assets/svg";

function ContactUs() {
  const [isLoading, setIsLoading] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    const delay = 1250;
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, delay);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className={styles.contactusContainer}>
        <span className={styles.locationHeading}>Store Location</span>
        <div className={styles.storeLocationInConatctUs}>
          <div className={styles.storeLocator}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2749.672563094989!2d77.5823415986468!3d12.931856080305286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15983d8d8f1f%3A0x34637b473d0c59!2sAabushan!5e0!3m2!1sen!2sin!4v1709096262078!5m2!1sen!2sin"
              width="100%"
              height={width < 480 ? "400" : "560"}
              style={{ border: "0" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div className={styles.formContainer}>
          <p className={styles.connectWithUsHeading}>Connect with US</p>
          <div className={styles.contactusForm}>
            <div className={styles.imageConatiner}>
              <img src={require("../../assets/images/contact-us.jpg")} alt="" />
            </div>
            <div>
              <ContactUsForm />
            </div>
          </div>
        </div>
        <div className={styles.getInTouchContainer}>
          <p className={styles.getInTouchContainerHeading}>Get In Touch</p>
          <div className={styles.getInTouchComponents}>
            <div className={styles.getInTouchElements}>
              <MapPin size={48} />
              <div className={styles.addressComponent}>
                <p>185/7, 8th F Main Rd,</p>
                <p>3rd Block East, Jayanagar,</p>
                <p>Bengaluru, Karnataka 560011</p>
              </div>
            </div>
            <div className={styles.getInTouchElements}>
              <Phone size={48} />
              <p>080 2653 2633</p>
            </div>
            <div className={styles.getInTouchElements}>
              <EnvelopeSimple size={48} />
              <p>aabushanjewellery@gmail.com</p>
            </div>
            <div className={styles.getInTouchElements}>
              <div className={styles.svgContainer}>
                <GST />
              </div>
              <p>GSTIN: 29AAGFA8295F123</p>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <LoadingComponent isLoading={isLoading} time={120} />}
    </>
  );
}

export default ContactUs;
