import React from "react";
import { HashRouter, Route, Routes, Navigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";

// pages
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Products from "./pages/Products";
import ContactUs from "./pages/ContactUs";
import SavingScheme from "./pages/SavingScheme";
import BookAppointment from "./pages/BookAppointment";
import PrivacyPolicy from "./pages/PrivacyPolicy";

// components
import Header from "./components/Header";
import Footer from "./components/Footer";

// Styles
import styles from "./styles/App.module.scss";

// Redux
import { persistor, store } from "./store/reducers/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import ProductDetail from "./pages/ProductDetail";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HashRouter>
          <Toaster position="top-right" reverseOrder={false} />
          <div className={styles.appContainer}>
            <Header />
            <div className={styles.routeContent}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/products" element={<Products />} />
                <Route path="/product/:mode" element={<ProductDetail />} />
                <Route path="/contact" element={<ContactUs />} />
                <Route path="/saving-scheme" element={<SavingScheme />} />
                <Route path="/book-appointment" element={<BookAppointment />} />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/*" element={<Navigate to="/" />} />
              </Routes>
            </div>
            <a
              href="https://wa.link/c4j6ue"
              target="_blank"
              className={styles.whatsappContainer}
            >
              <img
                src={require("./assets/images/WhatsApp_icon.png")}
                alt="Whatsapp"
                className={styles.whatsappLogo}
              />
            </a>
            <Footer />
          </div>
        </HashRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
