export const SET_JEWELLERY_TYPE_DATA = "SET_JEWELLERY_TYPE_DATA";
export const SET_JEWELLERY_TYPE_ERROR = "SET_JEWELLERY_TYPE_ERROR";
export const FETCHING_JEWELLERY_TYPE_DATA = "FETCHING_JEWELLERY_TYPE_DATA";

export const SET_JEWELLERY_MATERIAL_DATA = "SET_JEWELLERY_MATERIAL_DATA";
export const SET_JEWELLERY_MATERIAL_ERROR = "SET_JEWELLERY_MATERIAL_ERROR";
export const FETCHING_JEWELLERY_MATERIAL_DATA =
  "FETCHING_JEWELLERY_MATERIAL_DATA";

export const SET_TAGS_DATA = "SET_TAGS_DATA";
export const SET_TAG_ERROR = "SET_TAG_ERROR";
export const FETCHING_TAGS_DATA = "FETCHING_TAGS_DATA";

export const SET_PRODUCT_DATA = "SET_PRODUCT_DATA";
export const SET_PRODUCT_ERROR = "SET_PRODUCT_ERROR";
export const FETCHING_PRODUCT_DATA = "FETCHING_PRODUCT_DATA";

export const RESET_DATA = "RESET_DATA";
