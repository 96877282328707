import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import styles from "../../styles/ProductDetail.module.scss";
import { useSelector } from "react-redux";
import { Modal } from "@mui/material";
import ContactUsForm from "../../components/ContactUsForm";
import Button from "../../components/Button";

function ProductDetail() {
  const { state } = useLocation();

  const jewelleryMaterial = useSelector(
    ({ mainReducer }) => mainReducer.jewelleryMaterialData
  );

  const jewelleryType = useSelector(
    ({ mainReducer }) => mainReducer.jewelleryTypeData
  );

  const imgRef = useRef(null);

  const [showEnquiryModal, setShowEnquiryModal] = useState(false);

  useEffect(() => {
    if (imgRef.current.complete) {
      magnify("myImage", 3);
    } else {
      imgRef.current.onload = () => magnify("myImage", 3);
    }
  }, []);

  function magnify(imgID, zoom) {
    var img, glass, w, h, bw;
    img = document.getElementById(imgID);

    /* Create magnifier glass: */
    glass = document.createElement("DIV");
    glass.className = styles.imgMagnifierGlass; // Corrected class assignment

    /* Insert magnifier glass: */
    img.parentElement.insertBefore(glass, img);

    /* Set background properties for the magnifier glass: */
    glass.style.backgroundImage = "url('" + img.src + "')";
    glass.style.backgroundRepeat = "no-repeat";
    glass.style.backgroundSize =
      img.width * zoom + "px " + img.height * zoom + "px";
    bw = 3;
    w = glass.offsetWidth / 2;
    h = glass.offsetHeight / 2;

    /* Execute a function when someone moves the magnifier glass over the image: */
    glass.addEventListener("mousemove", moveMagnifier);
    img.addEventListener("mousemove", moveMagnifier);

    /* And also for touch screens: */
    glass.addEventListener("touchmove", moveMagnifier);
    img.addEventListener("touchmove", moveMagnifier);

    function moveMagnifier(e) {
      var pos, x, y;
      /* Prevent any other actions that may occur when moving over the image */
      e.preventDefault();
      /* Get the cursor's x and y positions: */
      pos = getCursorPos(e);
      x = pos.x;
      y = pos.y;

      /* Prevent the magnifier glass from being positioned outside the image: */
      if (x > img.width - w / zoom) {
        x = img.width - w / zoom;
      }
      if (x < w / zoom) {
        x = w / zoom;
      }
      if (y > img.height - h / zoom) {
        y = img.height - h / zoom;
      }
      if (y < h / zoom) {
        y = h / zoom;
      }

      /* Set the position of the magnifier glass: */
      glass.style.left = x - w + "px";
      glass.style.top = y - h + "px";

      /* Display what the magnifier glass "sees": */
      glass.style.backgroundPosition =
        "-" + (x * zoom - w + bw) + "px -" + (y * zoom - h + bw) + "px";
    }

    function getCursorPos(e) {
      var a,
        x = 0,
        y = 0;
      e = e || window.event;

      /* Get the x and y positions of the image: */
      a = img.getBoundingClientRect();

      /* Calculate the cursor's x and y coordinates, relative to the image: */
      x = e.pageX - a.left;
      y = e.pageY - a.top;

      /* Consider any page scrolling: */
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      return { x: x, y: y };
    }
  }

  const handleCloseEnquiry = () => {
    setShowEnquiryModal(false);
  };

  const toggleEnquiryModal = () => {
    setShowEnquiryModal((prev) => !prev);
  };

  const getJewelleryType = (jewelleryTypeId) => {
    return (
      jewelleryType.find((item) => item.jewelleryTypeId === jewelleryTypeId)
        ?.jewelleryType || ""
    );
  };

  const getJewelleryMaterial = (jewelleryMaterialId) => {
    return (
      jewelleryMaterial.find(
        (item) => item.jewelleryMaterialId === jewelleryMaterialId
      )?.jewelleryMaterial || ""
    );
  };

  return (
    <div className={styles.productDetailContainer}>
      <div className={styles.productImage}>
        <img
          ref={imgRef}
          src={state.imageURL}
          alt={state.name}
          className={styles.image}
          id="myImage"
        />
      </div>
      <div className="product-info">
        <h2>{state.name}</h2>
        <h4>{state.description}</h4>
        <h5>Material : {getJewelleryType(state?.jewelleryType)}</h5>
        <h5>Category: {getJewelleryMaterial(state?.jewelleryMaterial)}</h5>
        <div className={styles.enquiryButton}>
          <Button
            text="Enquiry"
            style="solid"
            size={window.innerWidth <= 480 ? "small" : ""}
            onClick={toggleEnquiryModal}
          />
        </div>
      </div>

      {showEnquiryModal && (
        <Modal open={true}>
          <div className={styles.contactUsFormInProducts}>
            <ContactUsForm cancel={true} onClose={handleCloseEnquiry} />
          </div>
        </Modal>
      )}
    </div>
  );
}

export default ProductDetail;
