import React from "react";

// Styles
import "../styles/Button.scss";

function Button({ text, onClick, disabled, loading, style, size }) {
  let buttonClasses = `${loading ? "button--loading" : ""}`;
  let buttonTextClasss = "button__text";

  if (style === "solid") {
    buttonClasses += " button-solid";
    buttonTextClasss = "button__text-solid";
  } else {
    buttonClasses += " button-outlined";
    buttonTextClasss = "button__text-outlined";
  }
  if (size === "large") {
    buttonClasses += " button-large";
  }
  if (size === "small") {
    buttonClasses += " button-small";
  }
  if (disabled) {
    buttonClasses += " button-disabled";
    buttonTextClasss += " button__text-disabled";
  }

  return (
    <button className={buttonClasses} onClick={onClick} disabled={disabled}>
      <span className={buttonTextClasss}>{text}</span>
    </button>
  );
}

export default Button;
