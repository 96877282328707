import React, { useEffect, useState } from "react";
import LoadingComponent from "../../components/LoadingComponent";

import styles from "../../styles/AboutUs.module.scss";

function AboutUs() {
  const [isLoading, setIsLoading] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    const delay = 1250;
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, delay);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className={styles.aboutusContainer}>
        <h3 className={styles.aboutusHeading}>About Us</h3>
        <div className={styles.aboutusDetailContainer}>
          <div className={styles.aboutusDetailImage}>
            <img
              src={require("../../assets/images/AboutUsDetail.jpg")}
              alt=""
            />
          </div>
          <div className={styles.aboutusDetailText}>
            <p>
              Crafting elegance and luxury since 1941. Discover Bangalore's
              finest in silver, gold, diamond, and platinum at Aabushan. Your
              timeless sparkle awaits.
            </p>
            <p>
              Our illustrious jewelry brand - Aabushan traces its roots to the
              iconic KGF gold mines, cultivating a legacy spanning generations.
              With an unwavering commitment to excellence, we specialize in
              crafting exquisite pieces in gold, silver, diamond, and platinum.
              Every creation reflects a timeless fusion of heritage and
              contemporary elegance. And add a picture.
            </p>
          </div>
        </div>
        <div className={styles.founderContainer}>
          <p className={styles.founderHeading}>Our Founders</p>
          <p className={styles.imageTextB}>Four Generations of Excellence</p>
          <p className={styles.imageTextC}>
            Currently handled and managed by{" "}
            <strong className={styles.managerName}>Mr. Mahaveer Bohra</strong>{" "}
            and <strong className={styles.managerName}>Mr. Vishal Bohra</strong>
            .
          </p>
          <div className={styles.founderImagesContainer}>
            <div className={styles.founderImage}>
              <img
                src={require("../../assets/images/founder11.jpeg")}
                alt="Founder"
                className={styles.imageFounder}
              />
            </div>
            <div className={styles.founderImage}>
              <img
                src={require("../../assets/images/founder12.jpeg")}
                alt="Founder"
                className={styles.imageFounder}
              />
            </div>
          </div>
          {/* <div className={styles.founderImagesContainer}>
            <div className={`${styles.imageWithText} ${styles.mobileView}`}>
              <div className={styles.founderImage}>
                <img
                  src={require("../../assets/images/founder11.jpeg")}
                  alt="Founder"
                  className={styles.imageFounder}
                />
              </div>
              <div className={styles.imageText}>
                {width > 480 ? (
                  <>
                    Currently handled and managed by{" "}
                    <strong>Mr Vishal Bohra</strong> and{" "}
                    <strong>Mr. Mahaveer Bohra.</strong>
                  </>
                ) : null}
              </div>
            </div>
            <div className={`${styles.imageWithText} ${styles.mobileView}`}>
              <p className={styles.imageText}>
                {width <= 480 ? (
                  <>
                    Currently handled and managed by{" "}
                    <strong>Mr Vishal Bohra</strong> and{" "}
                    <strong>Mr. Mahaveer Bohra.</strong>
                    <br />
                    <br />
                  </>
                ) : null}
                <span>"</span>With intricate, handcrafted designs, Aabushan
                Jewellers offers jewellery for every occassion. Simply walk into
                our store or look through our website to get an idea of the
                sheer range variety we offer. Every piece from Aabushan
                Jewellers flatters the neo-classical princess within you!
                <span>"</span>
              </p>
              <div className={styles.founderImage}>
                <img
                  src={require("../../assets/images/founder12.jpeg")}
                  alt="Founder"
                  className={styles.imageFounder}
                />
              </div>
            </div>
          </div> */}
        </div>
        <div className={styles.awardsContainer}>
          <p className={styles.awardsHeading}>Awards & Achivements</p>
          <div className={styles.awards}>
            <div className={styles.awardsSection1}>
              <div>
                <p className={styles.awardsText}>
                  <strong>Three times winners of the prestigious</strong>
                  <strong>National Jewellery Awards</strong>
                </p>
                <p className={styles.awardsText}>
                  <img
                    src={require("../../assets/images/awards/awards1.png")}
                    alt="awards"
                  />
                </p>
                <p className={styles.awardsText}>
                  National Jewellery Awards – 2018
                </p>
                <p className={styles.awardsText}>National Jewellery Awards :</p>
                <p className={styles.awardsText}>
                  Paramparik Jewellery South – 2015
                </p>
                <p className={styles.awardsText}>National Jewellery Awards:</p>
                <p className={styles.awardsText}>
                  Jadau Jewellery South – 2014
                </p>
              </div>
            </div>
            <div className={styles.awardsSection2}>
              <div className={styles.awardsSection20}>
                <p className={styles.awardsText}>
                  <img
                    src={require("../../assets/images/awards/awards2.png")}
                    alt="awards"
                  />
                </p>
                <p className={styles.awardsText}>Times Retail Icon :</p>
                <p className={styles.awardsText}>
                  Jewels Of Distinction awarded by
                </p>
                <p className={styles.awardsText}>Times Group – 2017</p>
              </div>
              <div className={styles.awardsSection20}>
                <p className={styles.awardsText}>
                  <img
                    src={require("../../assets/images/awards/awards3.png")}
                    alt="awards"
                  />
                </p>
                <p className={styles.awardsText}>Govt., of India approves </p>
                <p className={styles.awardsText}>Aabushan</p>
                <p className={styles.awardsText}>As Gold Valuer Pan India</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <LoadingComponent isLoading={isLoading} time={120} />}
    </>
  );
}

export default AboutUs;
