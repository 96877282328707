import React, { useRef, useState } from "react";
import styles from "../styles/ContactUsForm.module.scss";
import Button from "./Button";
import { Close } from "../assets/svg";
import toast from "react-hot-toast";
import ApiUtils from "../util/ApiUtil";

function ContactUsForm({ cancel, onClose, text = "Price for Jewellwery" }) {
  const name = useRef();
  const email = useRef();
  const phone = useRef();
  const description = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const phoneNumberRegex =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const handlePhoneInputChange = (event) => {
    let value = event.target.value;
    value = value.replace(/\D/g, "");
    if (value && value.length > 10) {
      value = value.substring(0, 10);
    }
    phone.current.value = value;
  };

  const handleSaveClick = async () => {
    if (name.current?.value === "") {
      toast.error("Please enter name");
      return;
    }
    if (email.current?.value === "") {
      toast.error("Please enter email");
      return;
    }
    if (!emailRegex.test(email.current?.value)) {
      toast.error("Please enter a valid email");
      return;
    }
    if (phone.current?.value === "") {
      toast.error("Please enter phone number");
      return;
    }
    if (!phoneNumberRegex.test(phone.current?.value)) {
      toast.error("Please enter a valid phone number");
      return;
    }
    if (description.current?.value === "") {
      toast.error("Please enter a valid description");
      return;
    }

    setIsLoading(true);
    setIsDisabled(true);

    try {
      const data = {
        name: name.current.value,
        email: email.current.value,
        phone: phone.current.value,
        note: description.current.value,
      };

      const response = await ApiUtils.postFormData(data);
      toast.success("Message sent successfully");
      if (!!onClose) {
        onClose();
      }
    } catch (error) {
      toast.error(
        error?.response?.data ||
          "Could not send the note. Please try again later"
      );
    } finally {
      name.current.value = "";
      email.current.value = "";
      phone.current.value = "";
      description.current.value = "";
      setIsLoading(false);
      setIsDisabled(false);
    }
  };

  return (
    <div className={styles.form}>
      {!!cancel && (
        <div className={styles.formClose} onClick={() => onClose()}>
          <Close />{" "}
        </div>
      )}
      <div className={styles.formRow}>
        <label className={styles.inputLabel} htmlFor="name">
          Name<span className={styles.required}>*</span>
        </label>
        <input
          type="text"
          id="name"
          ref={name}
          placeholder="Enter your name"
          className={styles.inputText}
        />
      </div>
      <div className={styles.formRow}>
        <label className={styles.inputLabel} htmlFor="email">
          Email<span className={styles.required}>*</span>
        </label>
        <input
          type="email"
          id="email"
          ref={email}
          placeholder="Enter your email"
          className={styles.inputText}
        />
      </div>
      <div className={styles.formRow}>
        <label className={styles.inputLabel} htmlFor="phone">
          Phone<span className={styles.required}>*</span>
        </label>
        <input
          type="tel"
          id="phone"
          ref={phone}
          placeholder="Enter your phone number"
          className={styles.inputText}
          onInput={handlePhoneInputChange}
        />
      </div>
      <div className={styles.formRow}>
        <label className={styles.inputLabel} htmlFor="description">
          What are you looking for?<span className={styles.required}>*</span>
        </label>
        <input
          type="text"
          id="description"
          ref={description}
          placeholder={text}
          className={styles.inputText}
        />
      </div>
      <div className={styles.ContactUsFormButton}>
        <Button
          text="Send"
          onClick={handleSaveClick}
          disabled={isDisabled}
          loading={isLoading}
          style="solid"
        ></Button>
      </div>
    </div>
  );
}

export default ContactUsForm;
