import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// components
import CarouselComponent from "./components/CarouselComponent";
import ProductsSection from "./components/ProductsSection";

// styles
import styles from "../../styles/Home.module.scss";

import LoadingComponent from "../../components/LoadingComponent";

import { Search, Delivery, Present, PriceTag } from "../../assets/svg";
import {
  getJewelleryMaterialData,
  getJewelleryTypeData,
  getProductData,
  getTagsData,
} from "../../store/actions/MainAction";
import { linkCorousel } from "../../util/HomeUtil";
import BestSeller from "./components/BestSeller";

function Home() {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    dispatch(getJewelleryTypeData());
    dispatch(getJewelleryMaterialData());
    dispatch(getTagsData());
    dispatch(getProductData());
  }, []);

  useEffect(() => {
    const delay = 2500;
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, delay);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={styles.patternImage}>
      <div className={styles.sectionContainer}>
        <CarouselComponent slideData={linkCorousel} width={width} />
      </div>
      <div className={styles.bestSellerCorouselComponent}>
        <p className={styles.bestSellerHeading}>Best Seller</p>
        {/* <div className={styles.sectionContainer}>
          <CarouselComponent slideData={bestSellerCorousel} />
        </div> */}
        <BestSeller />
      </div>
      <div className={styles.sectionContainer}>
        <ProductsSection />
      </div>
      <div className={styles.trustIsSafe}>
        <p className={styles.trustIsSafeHeading}>Trust Is Safe</p>
        <p className={styles.trustIsSafeSubHeading}>
          Offering you finest ornaments designed with taste
        </p>
        <div className={styles.trustItems}>
          <div className={styles.trustItem}>
            <div className={styles.trustItemSVG}>
              <Search />
            </div>
            <div className={styles.trustItemImageText}>Customized Jewellry</div>
          </div>
          <div className={styles.trustItem}>
            <div className={styles.trustItemSVG}>
              <Delivery />
            </div>
            <div className={styles.trustItemImageText}>Shipping Worldwide</div>
          </div>
          <div className={styles.trustItem}>
            <div className={styles.trustItemSVG}>
              <Present />
            </div>
            <div className={styles.trustItemImageText}>82 Years of Trust</div>
          </div>
          <div className={styles.trustItem}>
            <div className={styles.trustItemSVG}>
              <PriceTag />
            </div>
            <div className={styles.trustItemImageText}>
              Transparent Lowest Price
            </div>
          </div>
        </div>
      </div>
      <div className={styles.storeLocatorContainer}>
        <p className={styles.locationHeading}>Store Location</p>
        <div className={styles.storeLocationInHome}>
          <div className={styles.storeLocator}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2749.672563094989!2d77.5823415986468!3d12.931856080305286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15983d8d8f1f%3A0x34637b473d0c59!2sAabushan!5e0!3m2!1sen!2sin!4v1709096262078!5m2!1sen!2sin"
              width="100%"
              height={width < 480 ? "400" : "560"}
              style={{ border: "0" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
      {isLoading && <LoadingComponent isLoading={isLoading} time={240} />}
    </div>
  );
}

export default Home;
