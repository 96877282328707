import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "../../styles/ProductPage.module.scss";
import LoadingComponent from "../../components/LoadingComponent";
import Button from "../../components/Button";
import ContactUsForm from "../../components/ContactUsForm";
import { Modal } from "@mui/material";
import ApiUtils from "../../util/ApiUtil";
import { VideoCamera } from "@phosphor-icons/react";
import BookingForm from "../../components/BookingForm";

function Products() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const jewelleryMaterial = useSelector(
    ({ mainReducer }) => mainReducer.jewelleryMaterialData
  );

  const jewelleryType = useSelector(
    ({ mainReducer }) => mainReducer.jewelleryTypeData
  );

  const products = useSelector(({ mainReducer }) => mainReducer.productData);

  // const tags = useSelector(({ mainReducer }) => mainReducer.tagsData);

  const [selectedTypes, setSelectedTypes] = useState(["jewelleryTypeAllId"]);
  const [selectedMaterials, setSelectedMaterials] = useState([
    "jewelleryMaterialAllId",
  ]);
  const [productData, setProductData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const [shouldShowFilterInMobile, setShouldShowFilterInMobile] =
    useState(false);
  const [showEnquiryModal, setShowEnquiryModal] = useState(false);
  const [availableAppointmentSlot, setAvailableAppointmentSlot] = useState([]);
  const [showVideoBookingModal, setShowVideoBookingModal] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState();

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    getAppointmentSlot();
    const delay = 2500;
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, delay);

    return () => clearTimeout(timer);
  }, []);

  const getAppointmentSlot = async () => {
    try {
      const response = await ApiUtils.getAppointmentSlots();
      let data = response.data;
      data = data?.filter(
        (appointment) => appointment?.videoAppointmentArray?.length > 0
      );
      setAvailableAppointmentSlot(data);
    } catch (error) {
      setAvailableAppointmentSlot([]);
    }
  };

  useEffect(() => {
    if (state) {
      setSelectedMaterials([state.materialId]);
    }
  }, [state]);

  useEffect(() => {
    const filteredProducts = products.filter((product) => {
      const typeMatch =
        selectedTypes.includes("jewelleryTypeAllId") ||
        selectedTypes.includes(product.jewelleryType);

      const materialMatch =
        selectedMaterials.includes("jewelleryMaterialAllId") ||
        selectedMaterials.includes(product.jewelleryMaterial);

      return typeMatch && materialMatch;
    });

    setProductData(filteredProducts);
  }, [selectedTypes, selectedMaterials, products]);

  const jewelleryMaterialOptionData = useMemo(() => {
    const allOption = { id: "jewelleryMaterialAllId", name: "All" };
    if (!!jewelleryMaterial && Array.isArray(jewelleryMaterial)) {
      const options = jewelleryMaterial.map(
        ({ jewelleryMaterialId, jewelleryMaterial }) => ({
          id: jewelleryMaterialId,
          name: jewelleryMaterial,
        })
      );
      return [allOption, ...options];
    }
  }, [jewelleryMaterial]);

  const jewelleryTypeOptionData = useMemo(() => {
    if (!!jewelleryType && Array.isArray(jewelleryType)) {
      const allOption = { id: "jewelleryTypeAllId", name: "All" };
      const options = jewelleryType.map(
        ({ jewelleryTypeId, jewelleryType }) => ({
          id: jewelleryTypeId,
          name: jewelleryType,
        })
      );
      return [allOption, ...options];
    }
  }, [jewelleryType]);

  const handleTypeChange = (event) => {
    const type = event.target.value;
    if (type === "jewelleryTypeAllId") {
      setSelectedTypes(["jewelleryTypeAllId"]);
    } else {
      setSelectedTypes((prevTypes) => {
        if (prevTypes.includes("jewelleryTypeAllId")) {
          return [type];
        } else {
          // Toggle selection of current type
          const updatedTypes = prevTypes.includes(type)
            ? prevTypes.filter((t) => t !== type)
            : [...prevTypes, type];
          // If no type is selected, automatically select "All"
          return updatedTypes.length === 0
            ? ["jewelleryTypeAllId"]
            : updatedTypes;
        }
      });
    }
  };

  const handleMaterialChange = (event) => {
    const material = event.target.value;
    if (material === "jewelleryMaterialAllId") {
      setSelectedMaterials(["jewelleryMaterialAllId"]);
    } else {
      setSelectedMaterials((prevMaterials) => {
        if (prevMaterials.includes("jewelleryMaterialAllId")) {
          return [material];
        } else {
          // Toggle selection of current material
          const updatedMaterials = prevMaterials.includes(material)
            ? prevMaterials.filter((m) => m !== material)
            : [...prevMaterials, material];
          // If no material is selected, automatically select "All"
          return updatedMaterials.length === 0
            ? ["jewelleryMaterialAllId"]
            : updatedMaterials;
        }
      });
    }
  };

  const handleVideoAppointmentClick = (producId) => {
    setSelectedProductId(producId);
    setShowVideoBookingModal((prev) => !prev);
  };

  const onCloseVideoAppointment = () => {
    setSelectedProductId(null);
    setShowVideoBookingModal(false);
  };

  const toggleFilterInMobile = () => {
    setShouldShowFilterInMobile((prev) => !prev);
  };

  const toggleEnquiryModal = () => {
    setShowEnquiryModal((prev) => !prev);
  };

  const handleCloseEnquiry = () => {
    setShowEnquiryModal(false);
  };

  const handleImageClick = (product) => {
    const product_url_name = product?.name?.toLowerCase().replace(/\s+/g, "-");
    navigate(`/product/${product_url_name}`, { state: { ...product } });
  };

  return (
    <>
      <div className={styles.productContainer}>
        {width <= 480 && (
          <p
            onClick={toggleFilterInMobile}
            className={styles.filterMobileButton}
          >
            Filter
          </p>
        )}
        {width > 480 || (shouldShowFilterInMobile && width <= 480) ? (
          <div className={styles.productFilterContainer}>
            <div className={styles.filter}>
              <p className={styles.filterHeading}>Material</p>
              {!!jewelleryMaterialOptionData &&
                Array.isArray(jewelleryMaterialOptionData) &&
                jewelleryMaterialOptionData.map((material) => (
                  <label key={material.id} className={styles.filterItem}>
                    <input
                      type="checkbox"
                      value={material.id}
                      onChange={handleMaterialChange}
                      checked={selectedMaterials.includes(material.id)}
                      className={styles.filterItemCheckBox}
                    />
                    <span className={styles.filterItemName}>
                      {material.name}
                    </span>
                  </label>
                ))}
            </div>
            <div className={styles.filter}>
              <p className={styles.filterHeading}>Category</p>
              {!!jewelleryTypeOptionData &&
                Array.isArray(jewelleryTypeOptionData) &&
                jewelleryTypeOptionData.map((type) => (
                  <label key={type.id} className={styles.filterItem}>
                    <input
                      type="checkbox"
                      value={type.id}
                      onChange={handleTypeChange}
                      checked={selectedTypes.includes(type.id)}
                      className={styles.filterItemCheckBox}
                    />
                    <span className={styles.filterItemName}>{type.name}</span>
                  </label>
                ))}
            </div>
          </div>
        ) : null}
        <div className={styles.productDisplayContainer}>
          {!!productData &&
          Array.isArray(productData) &&
          productData.length > 0 ? (
            productData.map((product, index) => (
              <div className={styles.productItem} key={index}>
                <img
                  src={
                    product.imageURL || require("../../assets/images/Logo.png")
                  }
                  alt={product.name}
                  className={styles.productImage}
                  loading="lazy"
                  onClick={() => handleImageClick(product)}
                />
                {!!availableAppointmentSlot &&
                  availableAppointmentSlot?.length > 0 && (
                    <div
                      className={styles.videoButton}
                      onClick={() =>
                        handleVideoAppointmentClick(product?.productId)
                      }
                    >
                      <VideoCamera size={width < 480 ? 16 : 24} weight="bold" />
                    </div>
                  )}
                <div className={styles.productName}>{product.name}</div>
                <div className={styles.enquiryButton}>
                  <Button
                    text="Enquiry"
                    style="solid"
                    size={width <= 480 ? "small" : ""}
                    onClick={toggleEnquiryModal}
                  />
                </div>
              </div>
            ))
          ) : (
            <div>No products available</div>
          )}
        </div>
      </div>
      {isLoading ? <LoadingComponent isLoading={isLoading} time={240} /> : null}
      {showEnquiryModal && (
        <Modal open={true}>
          <div className={styles.contactUsFormInProducts}>
            <ContactUsForm cancel={true} onClose={handleCloseEnquiry} />
          </div>
        </Modal>
      )}
      {showVideoBookingModal && (
        <Modal open={true}>
          <div className={styles.videoAppointmentInProducts}>
            <BookingForm
              cancel={true}
              onClose={onCloseVideoAppointment}
              selectedProductId={selectedProductId}
              availableAppointmentSlot={availableAppointmentSlot}
              type="VIDEO"
              getAppointmentSlot={getAppointmentSlot}
            />
          </div>
        </Modal>
      )}
    </>
  );
}

export default Products;
