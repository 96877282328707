import React, { useEffect, useRef, useState } from "react";
import styles from "../../styles/BookAppointment.module.scss";
import toast from "react-hot-toast";
import ApiUtils from "../../util/ApiUtil";
import LoadingComponent from "../../components/LoadingComponent";
import BookingForm from "../../components/BookingForm";

function BookAppointment() {
  const [progressBarLoading, isProgressBarLoading] = useState(true);
  const [availableAppointmentSlot, setAvailableAppointmentSlot] = useState([]);

  useEffect(() => {
    getAppointmentSlot();
    const delay = 2500;
    const timer = setTimeout(() => {
      isProgressBarLoading(false);
    }, delay);

    return () => clearTimeout(timer);
  }, []);

  const getAppointmentSlot = async () => {
    try {
      const response = await ApiUtils.getAppointmentSlots();
      let data = response.data;
      data = data?.filter(
        (appointment) => appointment?.appointmentArray?.length > 0
      );
      setAvailableAppointmentSlot(data);
    } catch (error) {
      toast.error(
        "Due to some network issue booking appointment is not available"
      );
      setAvailableAppointmentSlot([]);
    }
  };

  return (
    <div className={styles.bookAppointmentContainer}>
      <div className={styles.bookAppointmentLeftContainer}>
        <p className={styles.appointmentHeading}>Book an Appointment</p>
        <p className={styles.appointmentSubHeading}>
          Book your preferred appointment time and we will connect you to one of
          our Jewellery consultant.
        </p>
        <BookingForm
          availableAppointmentSlot={availableAppointmentSlot}
          type="NORMAL"
          getAppointmentSlot={getAppointmentSlot}
        />
      </div>
      <div className={styles.bookAppointmentRightContainer}>
        <img src={require("../../assets/images/book-app.jpg")} alt="" />
      </div>
      {progressBarLoading && (
        <LoadingComponent isLoading={progressBarLoading} time={240} />
      )}
    </div>
  );
}

export default BookAppointment;
