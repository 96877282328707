import ApiUtils from "../../util/ApiUtil";
import {
  FETCHING_JEWELLERY_MATERIAL_DATA,
  FETCHING_JEWELLERY_TYPE_DATA,
  FETCHING_PRODUCT_DATA,
  FETCHING_TAGS_DATA,
  SET_JEWELLERY_MATERIAL_DATA,
  SET_JEWELLERY_MATERIAL_ERROR,
  SET_JEWELLERY_TYPE_DATA,
  SET_JEWELLERY_TYPE_ERROR,
  SET_PRODUCT_DATA,
  SET_PRODUCT_ERROR,
  SET_TAGS_DATA,
  SET_TAG_ERROR,
} from "./types";

export const makeErrorMessageAsNullInJewelleryMaterial = () => ({
  type: SET_JEWELLERY_MATERIAL_ERROR,
  payload: null,
});

export const makeErrorMessageAsNullInJewelleryType = () => ({
  type: SET_JEWELLERY_TYPE_ERROR,
  payload: null,
});

export const makeErrorMessageAsNullInTags = () => ({
  type: SET_TAG_ERROR,
  payload: null,
});

export const makeErrorMessageAsNullInProduct = () => ({
  type: SET_PRODUCT_ERROR,
  payload: null,
});

export const getJewelleryMaterialData = () => async (dispatch) => {
  try {
    dispatch({ type: FETCHING_JEWELLERY_MATERIAL_DATA, payload: true });
    const response = await ApiUtils.getJewelleryMaterialData();
    const data = response.data;
    dispatch({ type: SET_JEWELLERY_MATERIAL_DATA, payload: data });
    return data;
  } catch (error) {
    dispatch({
      type: SET_JEWELLERY_MATERIAL_ERROR,
      payload:
        error?.response?.data || "Failed to fetch jewellery material data",
    });
  }
};

export const getJewelleryTypeData = () => async (dispatch) => {
  try {
    dispatch({ type: FETCHING_JEWELLERY_TYPE_DATA, payload: true });
    const response = await ApiUtils.getJewelleryTypeData();
    const data = response.data;
    dispatch({ type: SET_JEWELLERY_TYPE_DATA, payload: data });
    return data;
  } catch (error) {
    dispatch({
      type: SET_JEWELLERY_TYPE_ERROR,
      payload: error?.response?.data || "Failed to fetch jewellery type data",
    });
  }
};

export const getTagsData = () => async (dispatch) => {
  try {
    dispatch({ type: FETCHING_TAGS_DATA, payload: true });
    const response = await ApiUtils.getTagsData();
    const data = response.data;
    dispatch({ type: SET_TAGS_DATA, payload: data });
    return data;
  } catch (error) {
    dispatch({
      type: SET_TAG_ERROR,
      payload: error?.response?.data || "Failed to fetch tags data",
    });
  }
};

export const getProductData = () => async (dispatch) => {
  try {
    dispatch({ type: FETCHING_PRODUCT_DATA, payload: true });
    const response = await ApiUtils.getProductData();
    const data = response.data;
    dispatch({ type: SET_PRODUCT_DATA, payload: data });
    return data;
  } catch (error) {
    dispatch({
      type: SET_PRODUCT_ERROR,
      payload: error?.response?.data || "Failed to fetch product data",
    });
  }
};
