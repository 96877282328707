import axios from "axios";
import {
  API_GET_APPOINTMENT_SLOTS,
  API_GET_JEWELLERY_MATERIAL,
  API_GET_JEWELLERY_TYPE,
  API_GET_PRODUCTS,
  API_GET_TAGS,
  API_MAKE_BOOKING,
  API_POST_FORM,
} from "../constant/api-constant";

const authToken = "null";

class ApiUtils {
  // static async doLogin(loginData) {
  //   try {
  //     const response = await axios.post(LOGIN, loginData);
  //     const data = response.data;

  //     return data;
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  static async doGet(url) {
    try {
      // const { authToken } = store.getState().loginReducer;
      const response = await axios.get(url, {
        headers: {
          Authorization: authToken,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async doPost(url, body) {
    try {
      // const { authToken } = store.getState().loginReducer;
      const response = await axios.post(url, body, {
        headers: {
          // Authorization: authToken,
          "content-type": "application/json",
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async doPut(url, body) {
    try {
      // const { authToken } = store.getState().loginReducer;
      const response = await axios.put(url, body, {
        headers: {
          // Authorization: authToken,
          "content-type": "application/json",
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async doDelete(url) {
    try {
      // const { authToken } = store.getState().loginReducer;
      const response = await axios.delete(url, {
        headers: {
          Authorization: authToken,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async getJewelleryTypeData() {
    return await ApiUtils.doGet(API_GET_JEWELLERY_TYPE);
  }

  static async getJewelleryMaterialData() {
    return await ApiUtils.doGet(API_GET_JEWELLERY_MATERIAL);
  }

  static async getTagsData() {
    return await ApiUtils.doGet(API_GET_TAGS);
  }

  static async getProductData() {
    return await ApiUtils.doGet(API_GET_PRODUCTS);
  }

  static async postFormData(data) {
    return await ApiUtils.doPost(API_POST_FORM, data);
  }

  static async getAppointmentSlots() {
    return await ApiUtils.doGet(API_GET_APPOINTMENT_SLOTS);
  }

  static async makeBooking(data) {
    return await ApiUtils.doPost(API_MAKE_BOOKING, data);
  }
}

export default ApiUtils;
