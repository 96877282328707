import React from "react";
import { Button, Card, Row, Col } from "react-bootstrap";
import styles from "../../../styles/Product.module.scss";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function ProductsSection() {
  const navigate = useNavigate();

  const category = useSelector(
    ({ mainReducer }) => mainReducer.jewelleryMaterialData
  );

  const handleButtonMaterialClick = (value) => {
    navigate("/products", {
      state: {
        materialId: value,
      },
    });
  };

  return (
    <div className={styles.productContainer}>
      <h2 className={styles.title}>Category</h2>
      <Row xs={2} md={2} lg={4} className="g-4">
        {!!category &&
          category.slice(0, 8).map((product) => (
            <Col key={product.jewelleryMaterialId}>
              <Card className={styles.productCard}>
                <Card.Img
                  className={styles.cardImage}
                  variant="top"
                  src={
                    product?.materialImageUrl ||
                    require("../../../assets/images/Logo.png")
                  }
                />
                <div className={styles.cardOverlay}>
                  <div className={styles.buttonContainer}>
                    <Button
                      variant="primary"
                      className={styles.customButton}
                      onClick={() =>
                        handleButtonMaterialClick(product?.jewelleryMaterialId)
                      }
                    >
                      {product.jewelleryMaterial}
                    </Button>
                  </div>
                </div>
              </Card>
            </Col>
          ))}
      </Row>
    </div>
  );
}

export default ProductsSection;
