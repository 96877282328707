import moment from "moment";
import React, { useRef, useState } from "react";
import toast from "react-hot-toast";
import Button from "./Button";

import styles from "../styles/BookingForm.module.scss";
import ApiUtils from "../util/ApiUtil";
import { Close } from "../assets/svg";

function BookingForm({
  cancel,
  onClose,
  selectedProductId,
  availableAppointmentSlot,
  type,
  getAppointmentSlot,
}) {
  const name = useRef();
  const email = useRef();
  const phone = useRef();

  const [availableTime, setAvailableTime] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleDateChange = (e) => {
    if (!e.target?.value || e.target?.value === "No date available") {
      setSelectedDate(null);
      return;
    }
    setSelectedDate(e.target.value);
    const timeFiltered = availableAppointmentSlot?.find(
      (slot) => slot?.date === e.target.value
    );
    setSelectedAppointment(timeFiltered?.appointmentId);
    if (type === "NORMAL") {
      setAvailableTime(timeFiltered?.appointmentArray);
    } else {
      setAvailableTime(timeFiltered?.videoAppointmentArray);
    }
  };

  const handleTimeChange = (e) => {
    setSelectedTime(e.target.value);
  };

  const handleSaveClick = async () => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const phoneNumberRegex =
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

    if (name.current?.value === "") {
      toast.error("Please enter name");
      return;
    }
    if (email.current?.value === "") {
      toast.error("Please enter email");
      return;
    }
    if (!emailRegex.test(email.current?.value)) {
      toast.error("Please enter a valid email");
      return;
    }
    if (phone.current?.value === "") {
      toast.error("Please enter phone number");
      return;
    }
    if (!phoneNumberRegex.test(phone.current?.value)) {
      toast.error("Please enter a valid phone number");
      return;
    }
    if (!selectedDate) {
      toast.error("Please select a date");
      return;
    }
    if (!selectedTime) {
      toast.error("Please select a time");
      return;
    }

    setIsLoading(true);
    setIsDisabled(true);

    try {
      const data = {
        type: type,
        name: name.current.value,
        email: email.current.value,
        phone: phone.current.value,
        date: selectedDate,
        time: selectedTime,
        productId: selectedProductId,
        appointmentId: selectedAppointment,
      };

      const response = await ApiUtils.makeBooking(data);
      toast.success("Booking made successfully");
    } catch (error) {
      toast.error(
        error?.response?.data ||
          "Could not make the booking. Please try again later"
      );
    } finally {
      name.current.value = "";
      email.current.value = "";
      phone.current.value = "";
      setSelectedAppointment(null);
      setSelectedDate(null);
      setSelectedTime(null);
      setIsLoading(false);
      setIsDisabled(false);
      setAvailableTime(null);
      getAppointmentSlot();
      if (cancel) {
        onClose();
      }
    }
  };

  return (
    <div className={styles.form}>
      {!!cancel && (
        <div className={styles.formClose} onClick={() => onClose()}>
          <Close />{" "}
        </div>
      )}
      <div className={styles.formRow}>
        <label className={styles.inputLabel} htmlFor="name">
          Name<span className={styles.required}>*</span>
        </label>
        <input
          type="text"
          id="name"
          ref={name}
          placeholder="Enter your name"
          className={styles.inputText}
        />
      </div>
      <div className={styles.formRow}>
        <label className={styles.inputLabel} htmlFor="email">
          Email<span className={styles.required}>*</span>
        </label>
        <input
          type="email"
          id="email"
          ref={email}
          placeholder="Enter your email"
          className={styles.inputText}
        />
      </div>
      <div className={styles.formRow}>
        <label className={styles.inputLabel} htmlFor="phone">
          Phone<span className={styles.required}>*</span>
        </label>
        <input
          type="tel"
          id="phone"
          ref={phone}
          placeholder="Enter your phone number"
          className={styles.inputText}
        />
      </div>

      <div className={styles.formRow}>
        <label className={styles.inputLabel} htmlFor="appointmentDate">
          Select Appointment Date<span className={styles.required}>*</span>
        </label>
        <select
          id="appointmentDate"
          value={selectedDate}
          onChange={(e) => handleDateChange(e)}
          className={styles.selectTag}
        >
          <option value="">Select Date</option>
          {availableAppointmentSlot.length > 0 ? (
            availableAppointmentSlot.map((appointment) => (
              <option key={appointment?.date} value={appointment?.date}>
                {moment(appointment.date).format("MMMM Do YYYY")}
              </option>
            ))
          ) : (
            <option key={"errorDate"} value={null}>
              No date available
            </option>
          )}
        </select>
      </div>

      {selectedDate && (
        <div className={styles.formRow}>
          <label className={styles.inputLabel} htmlFor="appointmentTime">
            Select Appointment Time
            <span className={styles.required}>*</span>
          </label>
          <select
            id="appointmentTime"
            value={selectedTime}
            onChange={(e) => handleTimeChange(e)}
            className={styles.selectTag}
          >
            <option value="">Select Time</option>
            {Array.isArray(availableTime) &&
              availableTime.length > 0 &&
              availableTime.map((time, index) => (
                <option key={index} value={time}>
                  {moment(time).format("h:mm A")}
                </option>
              ))}
          </select>
        </div>
      )}

      <div className={styles.bookAppointmentFormButton}>
        <Button
          text="Send"
          onClick={handleSaveClick}
          disabled={isDisabled}
          loading={isLoading}
          style="solid"
        ></Button>
      </div>
    </div>
  );
}

export default BookingForm;
