import React, { useState, useEffect } from "react";
import { LinearProgress, Typography, Box } from "@mui/material";

function LinearProgressWithLabel(props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "98%",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: "98%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          sx={{
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#a7585a",
              // backgroundColor: "#691b1d"
            },
          }}
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35, marginTop: "8px" }}>
        <Typography variant="h5" gutterBottom color={'white'}>{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function LoadingComponent({ isLoading, time }) {
  const [progress, setProgress] = useState(10);

  useEffect(() => {
    let timer;

    if (isLoading) {
      timer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? prevProgress : prevProgress + 10
        );
      }, time || 240);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isLoading]);

  return (
    <>
      {isLoading ? (
        <div style={{ width: "95vw", height: "100vh" }}>
          <div
            style={{
              position: "fixed",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#2f0203",
              zIndex: "9999",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ width: "22rem", height: "11rem" }}>
              <img
                src={require("../assets/images/Aabushan_Loader_Logo.png")}
                alt="AABUSHAN"
                style={{ width: "22rem", height: "11rem" }}
              />
            </div>
            <LinearProgressWithLabel value={progress} />
          </div>
        </div>
      ) : null}
    </>
  );
}

export default LoadingComponent;
