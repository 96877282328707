import React from "react";
import { Link } from "react-router-dom";
import {
  FaFacebook,
  FaGoogle,
  FaInstagram,
  FaPinterest,
  FaWhatsapp,
} from "react-icons/fa";
import styles from "../styles/Footer.module.scss";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className={styles.footerSection}>
          <div className={styles.footerImage}>
            <img
              src={require("../assets/images/Logo.png")}
              alt=""
              className={styles.imageFooter}
            />
          </div>
          <p>
            Crafting elegance and luxury since 1941. Discover Bangalore's finest
            in silver, gold, diamond, and platinum at Aabushan. Your timeless
            sparkle awaits.
          </p>
          <p>
            Our illustrious jewelry brand - Aabushan traces its roots to the
            iconic KGF gold mines, cultivating a legacy spanning generations.
            With an unwavering commitment to excellence, we specialize in
            crafting exquisite pieces in gold, silver, diamond, and platinum.
            Every creation reflects a timeless fusion of heritage and
            contemporary elegance. And add a picture.
          </p>
        </div>

        <div className={styles.footerConnectSection}>
          <h4 className={styles.footerConnectHeading}>Connect with us</h4>
          <ul>
            <li>
              <a
                href="https://www.facebook.com/AabushanBlr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook /> Facebook
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/aabushanjewellery1941/?igsh=MWc3NHZ5YXFzdm1qYw%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram /> Instagram
              </a>
            </li>
            <li>
              <a
                href="https://in.pinterest.com/aabushanjewellery/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaPinterest /> Pinterest
              </a>
            </li>
            <li>
              <a
                href="https://g.co/kgs/XAWkbhv"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaGoogle /> Google
              </a>
            </li>
            <li>
              <a
                href="https://wa.link/c4j6ue"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaWhatsapp /> Whatsapp
              </a>
            </li>
          </ul>
        </div>

        <div className={styles.footerLinkSection}>
          <h4 className={styles.footerLinkHeading}>Quick Links</h4>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/products">Products</Link>
            </li>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
            <li>
              <Link to="/saving-scheme">Saving Scheme</Link>
            </li>
            <li>
              <Link
                to="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
