export const saving_scheme_details = [
  "Duration of the scheme will be 10 months",
  "Bonus will be given on completion of 10 months",
  "At the end of the scheme the member can purchase Gold, Diamond, Platinum Jewellery or Silver Articles from the ready stock or can place an order at the prevailing rates, Local Taxes Extra.",
  "Articles will not be given in advance.",
  "Monthly Instalments of all the schemes are to be paid within 10th of every month",
  "In Case of default articles will be given only at the end of the scheme for the amount deposited.",
  "Bonus will not be given to the defaulters.",
  "Cash will not be refunded under any circumstances.",
  "Decision of Aabushan management will be final.",
  "All disputes, if any, shall be subject to Bangalore Jurisdiction",
  "In case the pass book is lost by the member they shall have to pay Rs. 50/- for a duplicate pass book.",
  "Members are requested to make payment by cash, they are requested to obtain a cash receipt from the show room.",
  "If a member wishes to remit the instalment by cash, they are requested to obtain a cash receipt from the showroom.",
  "Aabushan management will reserve the right to alter, amend, add, delete or suspend the plan at any point of time. In such circumstances, equal to the value of instalments paid as on such date.",
];

export const saving_scheme_table = [
  [
    "Scheme No.",
    "Amount Payable Every Month",
    "Period 10 Months",
    "Bonus",
    "Total Purchase Value",
  ],
  ["1", "5,000", "50,000", "5,000", "55,000"],
  ["2", "10,000", "1,00,000", "10,000", "1,10,000"],
  ["3", "15,000", "1,50,000", "15,000", "1,65,000"],
  ["4", "20,000", "2,00,000", "20,000", "2,20,000"],
  ["5", "25,000", "2,50,000", "25,000", "2,75,000"],
];
